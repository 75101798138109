import React from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import { cloneDeep, has, isEmpty, remove } from 'lodash';
import { isValid as dateFnsIsValid } from 'date-fns/isValid';
import GatewayService from '../../services/GatewayService';
import {
  convertChatItemsToChatHistory,
  getUnreadChatMessagesCount,
  retrieveChatsFromCoreApi,
  sortAndDeDupeChats
} from '../../pages/helper/ChatMessages';
import { ERROR_RETRIEVING_CHAT, STUDENT, SUPERVISOR } from '../../constants/chat';
import { USER_TYPES } from '../../constants/users';

class ChatContainer extends React.Component {
  state = {
    activeParticipants: [],
    errorRetrievingChats: '',
    messages: [],
    unreadMessages: 0,
  };

  abortController = new AbortController();

  componentDidMount() {
    this.getChatHistory()
      .then(() => this.registerEvents());
  }

  componentDidUpdate(prevProps) {
    // If gateway service didn't exist on mount - Register chat message handler
    const { gatewayService } = this.props;
    if(prevProps.gatewayService === null && gatewayService) {
      this.registerEvents();
    }
  }

  componentWillUnmount() {
    const { gatewayService, showChatOnlyWithParticipant } = this.props;
    // Unregister chat message handler
    if (gatewayService) {
      gatewayService.unregisterHandler('onChatNotification', this.handleReceiveMessage);
      if (showChatOnlyWithParticipant) {
        gatewayService.unregisterHandlerWithSubtype('onParticipantChange', 'add', this.handleParticipantJoined);
        gatewayService.unregisterHandlerWithSubtype('onParticipantChange', 'remove', this.handleParticipantLeft);
      }
    }
    this.abortController.abort('[ChatContainer]: Aborting due to component unmount');
  }

  getChatHistory = async () => {
    retrieveChatsFromCoreApi(this.props.slotId, this.abortController.signal)
      .then(chatMessages => this.updateChatHistory(chatMessages))
      .catch(err => {
        console.error('[ChatContainer]: Failed to retrieve chat messages', err)
        this.setState({ errorRetrievingChats: ERROR_RETRIEVING_CHAT});
      });
  };

  updateChatHistory = (chatMessages) => {
    const chatHistory = convertChatItemsToChatHistory(chatMessages, this.props.userId, this.props.authorType);

    this.setState({
      unreadMessages: getUnreadChatMessagesCount(chatHistory, this.props.userId),
      messages: sortAndDeDupeChats(chatHistory),
    });
  };

  registerEvents() {
    const { gatewayService, showChatOnlyWithParticipant } = this.props;
    // Register chat message handler
    if(gatewayService) {
      gatewayService.registerChatNotificationEvent(this.handleReceiveMessage);
      if (showChatOnlyWithParticipant) {
        gatewayService.registerParticipantChangeEvent('add', this.handleParticipantJoined);
        gatewayService.registerParticipantChangeEvent('remove', this.handleParticipantLeft);
      }
    }
  };

  handleReceiveMessage = (messageData) => {
    const { userId, authorType } = this.props;
    if (messageData.type === 'MESSAGE' && has(messageData, 'text')) {
      const messageProps = {
        id: messageData.id,
        text: messageData.text,
        displayName: authorType === STUDENT.authorType ? SUPERVISOR.displayName : messageData.author,
        timestamp: dateFnsIsValid(messageData.timestamp) && new Date(messageData.timestamp),
        userId: messageData.userId,
        fromSelf: userId === messageData.userId,
      }
      flushSync(() => {
        this.setState({
          unreadMessages: messageData.read ? this.state.unreadMessages : this.state.unreadMessages + 1,
          messages: [...this.state.messages, messageProps],
        });
      });
    }
  };

  handleParticipantJoined = (userId, changeUserData, _reason) => {
    const { activeParticipants } = this.state;

    if (
      !activeParticipants.includes(userId)
      && changeUserData.hasOwnProperty("userType")
      && changeUserData.userType !== USER_TYPES.AGENT
    ) {
      this.setState({activeParticipants: [...activeParticipants, userId]});
    }
  }

  handleParticipantLeft = (userId, _changeUserData, _reason) => {
    const { activeParticipants } = this.state;
    if (activeParticipants.includes(userId)) {
      this.setState({activeParticipants: remove(activeParticipants, userId)})
    }
  }

  onSend(gatewayService, fullName, messages = []) {
    const audience = 'ALL';
    const prevMessages = cloneDeep(this.state.messages);

    messages.forEach(message => {
      if (message?.text?.length) {
        gatewayService.sendChat('MESSAGE', message.text, fullName, message.userId, audience);
        prevMessages.push(message);
      }
    });
    this.setState({
      messages: prevMessages,
    });
  }

  resetUnreadMessages = () => {
    this.setState({unreadMessages: 0})
  }

  render() {
    const { renderContainer } = this.props;
    const { activeParticipants, errorRetrievingChats, messages, unreadMessages } = this.state;

    if (!renderContainer) {
      // If the chat is flagged to be hidden, don't display it.
      return "";
    }

    const childrenWithProps = React.Children.map(this.props.children, child => {
      const props = {
        ...this.props,
        messages: messages,
        onSend: this.onSend.bind(this),
        unreadMessages: unreadMessages,
        resetUnreadMessages: this.resetUnreadMessages.bind(this),
        activeParticipants: !isEmpty(activeParticipants),
        errorRetrievingChats: errorRetrievingChats,
      };
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    });

    return (<>{childrenWithProps}</>);
  }
}

ChatContainer.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  displayName: PropTypes.string,
  userId: PropTypes.string,
  authorType: PropTypes.string,
  showChatOnlyWithParticipant: PropTypes.bool, // used when activeParticipants data is needed
  renderContainer: PropTypes.bool,
  slotId: PropTypes.string,
};
ChatContainer.defaultProps = {
  showChatOnlyWithParticipant: false,
  renderContainer: true,
};

export default (ChatContainer);
