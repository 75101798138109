import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fade, Tooltip, Typography } from '@mui/material';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import { assign, cloneDeep, has, isEmpty } from 'lodash';
import { AuthConsumer, authContext } from '../authContext';
import { CanThey } from '../components/Can';
import Notice from '../components/notification/Notice';
import ExamDetails from '../components/content/ExamDetails';
import Verification from '../components/popup/Verification';
import VideoStream from '../components/audioVisual/VideoStream';
import AudioStream from '../components/audioVisual/AudioStream';
import StreamsCheck from '../components/popup/StreamsCheck';
import SupervisorMediaControls from '../components/form/SupervisorMediaControls';
import EndButton from '../components/form/EndButton';
import ChatContainer from '../components/container/ChatContainer';
import FlagChatToggle from '../components/form/FlagChatToggle';
import BrowserCheckWrapper from '../components/popup/BrowserCheckWrapper';
import NotificationBar from '../components/notification/NotificationBar';
import ScreenTypeIndicator from '../components/notification/ScreenTypeIndicator'
import handler from '../components/audioVisual/handler';
import PromiseWithAbort from '../utils/PromiseWithAbort';
import VideoStreamService from '../services/VideoStreamService';
import ExamSessionService from '../services/ExamSessionService';
import GatewayService from '../services/GatewayService';
import AudioStreamService from '../services/AudioStreamService';
import { EXAM_SESSION_CAPABILITIES as CAPABILITIES, EXAM_SESSION_DELETION_STATUS, EXAM_SESSION_STATES } from '../constants/examSessions';
import { MEDIA_STREAM_CONNECTION_STATES as MEDIA_STREAM_STATE, SCREEN_SHARE_TYPES } from '../constants/mediaStates';
import { SUPERVISOR } from '../constants/chat';
import { USER_TYPES } from '../constants/users';
import { getStudentUser } from "../utils/getExamSlotUser";
import displaySupervisorPeerName from '../utils/displaySupervisorPeerName';
import withRouter from '../config/withRouter';

const styles = {
  pageContainer: {
    minHeight: '100vh',
  },
  panel: {
    userSelect: 'none',
    color: 'secondary.contrastText',
    backgroundColor: 'secondary.main',
    textAlign: 'center',
    marginRight: '1px',
  },
  swapIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  hidden: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
  audioContainer: {
    width: '173px',
    height: '33px',
    backgroundColor: '#000',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '10px',
    opacity: '10%',
    '&:hover': {
      opacity: '80%',
    },
  },
  audioContainerHidden: {
    display: 'none',
  },
  peerVideoContainer: {
    height: '15%',
    margin: 1,
    position: 'absolute',
    right: 0,
    bottom: '88px',
  },
  selfVideoContainer: {
    height: '15%',
    margin: 1,
    position: 'absolute',
  },
  swapPanelControl: {
    cursor: 'pointer',
  },
  connectionNotice: {
    backgroundColor: 'error.main',
    margin: 0,
    borderRadius: '0 0 50px 50px',
  },
  speakerHighlightActive: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '1',
    border: '3px solid',
    borderImageSource: 'linear-gradient(-45deg, #b5fc03, #ebfc03)',
    borderImageSlice: 1,
    color: 'transparent',
  },
};

/**
 * General panel for displaying VideoStream for student webcam or desktop, set by props.panel.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function Panel(props) {
  const { panel, onVideoError, screenShareType, streamId, studentDesktopReady, studentWebcamReady, videoStreamService, waitText } = props;
  if (videoStreamService && ('webcam' === panel && studentWebcamReady)) {
    return <VideoStream streamService={videoStreamService} streamId={streamId} isLocal={false} manageStream={false} onVideoError={onVideoError} />;
  } else if (videoStreamService && ('desktop' === panel && studentDesktopReady)) {
    // The streamId is always 'desktop' when streaming remote desktop.
    return (
      <>
        <VideoStream streamService={videoStreamService} streamId="desktop" isLocal={false} manageStream={false} onVideoError={onVideoError} />
        <ScreenTypeIndicator screenType={screenShareType}/>
      </>
    )
  } else {
    return (
      <Box flexGrow={1}>
        {waitText &&
          <Typography variant="h2" component="h3">
            <strong>Waiting for student {panel}</strong>
          </Typography>
        }
      </Box>
    )
  }
}

class SuperviseSession extends React.Component {

  state = {
    examDetails: undefined,
    studentDetails: undefined,
    hasRequestErrored: false,
    errorMessage: '',
    connectionErrors: {},
    connectionProps: null,
    gatewayConnection: null,
    studentAudioMedia: {
      ready: false,
      muted: true,
      active: false,
    },
    studentScreenShareMedia: {
      ready: false,
      stream: null,
    },
    screenShareType: SCREEN_SHARE_TYPES.NONE.mapper,
    studentVideoMedia: {
      ready: false,
      stream: null,
    },
    bigPanel: 'webcam',
    smallPanel: 'desktop',
    sharedMediaStatus: {
      audio: false,
      video: false,
    },
    streamsChecked: false,
    audioConnection: MEDIA_STREAM_STATE.CLOSED,
    videoConnection: MEDIA_STREAM_STATE.CLOSED,
    devices: {
      audioInputDevice: null,
      audioOutputDevice: null,
      videoDevice: null,
    },
    studentOnline: undefined,
    peers: {},
    videoOverride: {
      peer: null,
      stream: null,
      ready: false,
    },
    notification: {
      show: false,
      message: null,
    },
  }

  videoStreamService = null;
  audioStreamService = null;

  controller = new AbortController();
  asyncAbort = new PromiseWithAbort();

  getExamDetails = async () => {
    try {
      const examSessionDetails = await ExamSessionService.getExamSession(this.props.router?.match?.params?.slotId, this.controller.signal);
      if (examSessionDetails.deletionStatus === EXAM_SESSION_DELETION_STATUS.DELETED.value
        || examSessionDetails.deletionStatus === EXAM_SESSION_DELETION_STATUS.SCHEDULED.value) {
          throw new Error('Cannot join session for slot that is marked as ready for deletion or is deleted');
      }

      if (!this.controller.signal.aborted) {
        this.setState({
          examDetails: examSessionDetails,
          hasRequestErrored: false,
          errorMessage: '',
          studentDetails: getStudentUser(examSessionDetails),
        });
        if (
          CanThey(this.context.capabilityContextAccess, true, CAPABILITIES.superviseExam, { id: examSessionDetails.context.id }) ||
          CanThey(this.context.capabilityContextAccess, true, CAPABILITIES.onboardExam, { id: examSessionDetails.context.id })
        ) {
          await this.joinConference(examSessionDetails);
        }
      }
    } catch (error) {
      this.setRequestError('Unable to get exam details for that session. Please check your link and try again.');
      console.error('Could not get exam details', error);
    }
  }

  /**
   * Handler for when the browser warning check is passed, either due to the browser being valid
   * or the user selecting to close the warning popup.
   * @param {boolean} isValid
   * @param {object} browserObj
   */
  passBrowserCheck = (isValid, browserObj) => {
    this.context.authorised && this.getExamDetails();
  }

  /**
   * Wrapper for displaying a non-recoverable request error to the user on a failure, that checks whether
   * the component has been unmounted before updating the state.
   * @param {string} message The error message to display.
   */
  setRequestError = (message) => {
    if (!this.controller.signal.aborted) {
      this.setState({
        hasRequestErrored: true,
        errorMessage: message,
      });
    }
  }

  /**
   * Wrapper for displaying a recoverable connection error to the user on a failure, that checks whether
   * the component has been unmounted before updating the state. This error can be removed when the connection
   * is restored.
   * @param {string} connectionType The type of connection that failed.
   * @param {string} message The error message to display.
   * @param {boolean} clear True if the error has been resolved.
   */
  setConnectionError = (connectionType, message, clear = false) => {
    if (!this.controller.signal.aborted) {
      let newConnectErrors = cloneDeep(this.state.connectionErrors);
      if (!clear) {
        newConnectErrors[connectionType] = message;
      } else if (newConnectErrors[connectionType]) {
        delete newConnectErrors[connectionType];
      }
      this.setState({
        connectionErrors: newConnectErrors
      });
    }
  }

  joinConference = async (examDetails) => {
    const { user } = this.context;
    const slotId = examDetails?.id;
    try {

      if (!user) {
        throw new Error('No user yet');
      }

      // Obtain meeting details via API calls
      const meetingDetails = await ExamSessionService.joinMeeting(
        slotId,
        this.controller.signal
      );

      if (this.controller.signal.aborted) {
        return;
      }

      // Assuming we have successfully joined, open websocket session.
      const onScreenShareTypeHandler = handler.onScreenTypeUpdate(
        (action) => this.setState({ screenShareType: action?.value?.screenShareType }),
        slotId,
        USER_TYPES.SUPERVISOR
      );

      const gatewayConnection = new GatewayService();
      // Tell the supervisor if we are unexpectedly disconnected
      gatewayConnection.registerDisconnectionHandler(this.onGatewayDisconnected);
      // Clear the gateway error if the supervisor is successfully reconnected
      gatewayConnection.registerConnectionHandler(this.onGatewayReconnected);
      // Show or remove media elements from other users
      gatewayConnection.registerMediaChangeEvent('video', this.onRemoteVideoChange);
      gatewayConnection.registerMediaChangeEvent('audio', this.onRemoteAudioChange);
      gatewayConnection.registerMediaChangeEvent('screenshare', this.onRemoteScreenshareChange);
      // Shut everything down when the meeting is closed on BBB
      gatewayConnection.registerMeetingChangeEvent('end', this.onMeetingEnd);
      // When the gateway sends a terminate message, smoothly disconnect the current session (we can reuse the meeting
      // end function since we just need to close all things down).
      gatewayConnection.registerMeetingChangeEvent('terminated', this.onMeetingEnd);
      // Update the state when another user joins or leaves
      gatewayConnection.registerParticipantChangeEvent('add',
        (userId, userData, reason) => { this.onUserChange('add', userId, userData, reason) }
      );
      gatewayConnection.registerParticipantChangeEvent('remove',
        (userId, userData, reason) => { this.onUserChange('remove', userId, userData, reason) }
      );
      gatewayConnection.registerScreenTypeUpdateEvent(onScreenShareTypeHandler);

      // Now actually try to authenticate
      this.asyncAbort.wrap(gatewayConnection.connect(user.id, slotId, meetingDetails))
        .then(() => {
          // We have authenticated successfully with the websocket server
          this.videoStreamService = new VideoStreamService();
          this.audioStreamService = new AudioStreamService();
          this.setState({
            connectionProps: meetingDetails,
            gatewayConnection: gatewayConnection,
            audioConnection: MEDIA_STREAM_STATE.CONNECTING,
            videoConnection: MEDIA_STREAM_STATE.CONNECTING,
          }, () => {
            this.initAudio(true);
            this.initVideo();
          });
        })
        .catch((error) => {
          //Could not authenticate with gateway server.
          this.setRequestError("Could not connect to eVigilation gateway. Please close the page and try again.");
          console.error(`Could not connect to gateway`, { error });
          if (!this.controller.signal.aborted) {
            this.setState({
              sharedMediaStatus: {
                video: false,
                audio: false,
              },
            });
          }
        });
    } catch (error) {
      // Could not obtain meeting details.
      if (!this.controller.signal.aborted) {
        this.setRequestError("Could not connect to eVigilation session. Please close the page and try again.");
        console.error(`Could not join eVigilation session`, { error });
        if (!this.controller.signal.aborted) {
          this.setState({
            sharedMediaStatus: {
              video: false,
              audio: false,
            },
          });
        }
      }
    }
  }

  initAudio = async (echoTest) => {
    const { connectionProps } = this.state;
    let sharedMediaStatus = cloneDeep(this.state.sharedMediaStatus);
    await this.audioStreamService.open(connectionProps, this.audioRef)
      .catch(err => {
        this.setConnectionError('audio', 'There was a problem sharing your microphone. Please check your device and allow access before trying again.');
        this.logToGateway('supervisor_audio_open', { error: err, source: 'initAudio', connectionProps });
        if (!this.controller.signal.aborted) {
          sharedMediaStatus.audio = false;
          this.setState({ audioConnection: MEDIA_STREAM_STATE.CLOSED, sharedMediaStatus });
        }
        console.error(`Unable to open audio connection`, { err });
      });
    this.asyncAbort.wrap(
      this.audioStreamService.start(false, echoTest ? 'echo' : null)
    )
      .then(() => {
        this.setState({ audioConnection: MEDIA_STREAM_STATE.CONNECTED });
        this.setConnectionError('audio', '', true);
      })
      .catch((err) => {
        this.setConnectionError('audio', 'There was a problem sharing your microphone. Please check your device and allow access before trying again.');
        this.logToGateway('supervisor_audio_start', { error: err, source: 'initAudio' });
        if (!this.controller.signal.aborted) {
          sharedMediaStatus.audio = false;
          this.setState({ audioConnection: MEDIA_STREAM_STATE.CLOSED, sharedMediaStatus });
        }
        console.error(`Unable to join audio VOIP conference`, { err });
      });
  }

  initVideo = () => {
    const { connectionProps } = this.state;
    this.asyncAbort.wrap(
      this.videoStreamService.open(connectionProps, 'video')
    )
      .then(() => this.videoStreamService.open(connectionProps, 'screenshare'))
      .then(() => {
        this.setState({ videoConnection: MEDIA_STREAM_STATE.CONNECTED }, () => {
          // Start video if we've already received a gateway notification
          let newVideoMediaState = cloneDeep(this.state.studentVideoMedia);
          let newScreenShareMediaState = cloneDeep(this.state.studentScreenShareMedia);
          if (newVideoMediaState.stream) {
            this.videoStreamService.add(newVideoMediaState.stream, false, null);
            newVideoMediaState.ready = true;
          }
          if (newScreenShareMediaState.stream) {
            this.videoStreamService.add(newScreenShareMediaState.stream, false, null);
            newScreenShareMediaState.ready = true;
          }
          if (newVideoMediaState !== this.state.studentVideoMedia) {
            this.setState({ studentVideoMedia: newVideoMediaState });
          }
          if (newScreenShareMediaState !== this.state.studentScreenShareMedia) {
            this.setState({ studentScreenShareMedia: newScreenShareMediaState });
          }
          this.setConnectionError('video', '', true);

          // Start peer supervisor video if they were already sharing when we join
          let videoOverride = cloneDeep(this.state.videoOverride);
          if (videoOverride.peer && videoOverride.stream && !videoOverride.ready) {
            videoOverride.ready = true;
            this.videoStreamService.add(videoOverride.stream, false, null);
            this.setState({ videoOverride });
          }
        });
      })
      .catch((err) => {
        console.error(`Unable to open video connection`, err);
        this.setConnectionError('video', 'There was a problem sharing your webcam. Please check your device and allow access before trying again.');
        this.logToGateway('supervisor_video_open', { error: err, source: 'initVideo', connectionProps });
        if (!this.controller.signal.aborted) {
          this.setState({ videoConnection: MEDIA_STREAM_STATE.CLOSED });
        }
      });
  }

  closeCheck = (errors) => {
    const { connectionProps } = this.state;

    this.setState({ streamsChecked: true }, () => {
      if (has(errors, 'webcam')) {
        console.error(`Webcam preview error detected: `, errors.webcam);
        this.setConnectionError('video', 'There was a problem sharing your webcam. Please check your device and allow access before trying again.');
        this.logToGateway('supervisor_video_preview', { error: errors.webcam, source: 'webcamPreview', connectionProps });
      }
    });
  }

  updateDevices = (devices) => {
    const currentDevices = cloneDeep(this.state.devices);
    this.setState({ devices });
    if (currentDevices.audioInputDevice !== null && currentDevices.audioInputDevice !== devices.audioInputDevice) {
      // Change audio input
      this.setState({ audioConnection: MEDIA_STREAM_STATE.CONNECTING }, () => {
        this.asyncAbort.wrap(
          this.audioStreamService.changeInputDevice(devices.audioInputDevice, null))
          .then(() => {
            this.setState({ audioConnection: MEDIA_STREAM_STATE.CONNECTED });
            this.setConnectionError('audio', '', true);
          })
          .catch((err) => {
            this.setConnectionError('audio', 'There was a problem sharing your microphone. Please check your device and allow access before trying again.');
            this.logToGateway('supervisor_audio_change_input', { error: err, source: 'updateDevices', devices });
            if (!this.controller.signal.aborted) {
              this.setState({ audioConnection: MEDIA_STREAM_STATE.CLOSED });
            }
          });
      });
    }
    if (currentDevices.audioOutputDevice !== null && currentDevices.audioOutputDevice !== devices.audioOutputDevice) {
      // Change audio output
      this.audioStreamService.changeOutputDevice(devices.audioOutputDevice ? devices.audioOutputDevice : 'default')
        .catch((err) => {
          console.error(`Error while changing audio output device`, { err });
          this.logToGateway('supervisor_audio_change_output', { error: err, source: 'updateDevices', devices });
        });
    }
  }

  /**
   * When the gateway connection drops out, display a message to the user and handle any errors.
   * @param {string} reason The reason message for the disconnection.
   */
  onGatewayDisconnected = (reason) => {
    this.setConnectionError('gateway', 'You have been disconnected from your eVigilation session. If this error persists, please reload the page to reconnect.');
    console.error(`Gateway disconnected: ${reason}`);
  }

  /**
   * When the gateway connection returns after a dropout, clear error message.
   */
  onGatewayReconnected = () => {
    this.setConnectionError('gateway', '', true);
    console.info('Gateway connected successfully.');
  }

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their video streams, either starting a new stream or stopping an existing one.
   * @param {string} type Type of change, either 'start' or 'stop'.
   * @param {string} userId The eVig user ID who is sharing their video stream.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   */
  onRemoteVideoChange = (type, userId, userChangeData) => {
    const { videoConnection, examDetails, peers, sharedMediaStatus, videoOverride } = this.state;

    if (userId === examDetails.student.id) {
      // Receiving student webcam video
      let newVideoMediaState = cloneDeep(this.state.studentVideoMedia);
      if (type === 'start') {
        newVideoMediaState.stream = userChangeData.video.stream;
        if (videoConnection === MEDIA_STREAM_STATE.CONNECTED) {
          this.videoStreamService.add(newVideoMediaState.stream, false, null);
          newVideoMediaState.ready = true;
        }
      } else {
        const { stream: oldStream } = this.state.studentVideoMedia;
        if (oldStream !== null && videoConnection === MEDIA_STREAM_STATE.CONNECTED) {
          this.videoStreamService.remove(oldStream);
        }
        newVideoMediaState = {
          ready: false,
          stream: null,
        };
      }
      this.setState({ studentVideoMedia: newVideoMediaState });
    } else {
      // Receiving video from another supervisor
      let stateUpdate = {};
      let updPeers = cloneDeep(peers);
      if (!peers.hasOwnProperty(userId)) {
        // We need to add the new user sharing their webcam to our list of peers
        updPeers[userId] = userChangeData;
        stateUpdate.peers = updPeers;
      }

      let alertMessage = null;
      if (type === 'start') {
        // Other supervisor has started streaming to the student
        // We need to switch off our webcam if we were active
        if (sharedMediaStatus.video) {
          this.toggleVideoShare();
        }

        // Next set the active supervisor video to the one that is now being received
        stateUpdate.videoOverride = {
          peer: userId,
          stream: userChangeData.video.stream,
          ready: false,
        };
        if (videoConnection === MEDIA_STREAM_STATE.CONNECTED) {
          stateUpdate.videoOverride.ready = true;
          this.videoStreamService.add(userChangeData.video.stream, false, null);
        }
        alertMessage = displaySupervisorPeerName(userChangeData.userName) + ' started video share to student';
      } else {
        // Another supervisor is stopping their video
        if (videoOverride.peer === userId) {
          this.videoStreamService.remove(videoOverride.stream);
          stateUpdate.videoOverride = {
            peer: null,
            stream: null,
            ready: false,
          };
          alertMessage = displaySupervisorPeerName(userChangeData.userName) + ' stopped video share to student';
        }
      }
      if (alertMessage) {
        stateUpdate.notification = {
          show: true,
          message: alertMessage,
        };
      }
      if (stateUpdate) {
        this.setState(stateUpdate);
      }
    }
  }

  /**
   * Handle an event received that indicates a remote participant either joined or left.
   * @param {string} type Type of change, either 'add' or 'remove'.
   * @param {string} userId The eVig user ID who joined.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   * @param {string} reason The reason for this change
   */
  onUserChange = (type, userId, userChangeData, reason) => {
    const { studentDetails, peers, videoOverride } = this.state;
    let newVideoMediaState = cloneDeep(this.state.studentVideoMedia);
    let newScreenShareMediaState = cloneDeep(this.state.studentScreenShareMedia);
    let newAudioMediaState = cloneDeep(this.state.studentAudioMedia);
    console.info(`Change to ${type} meeting participant: ${userId} (${reason})`);
    if (userId === studentDetails.id) {
      // Student has come online or left
      if (type === 'add') {
        this.setState({
          studentOnline: true,
        });
      } else {
        // We need to stop any streams that are active if student has quit
        if (newVideoMediaState.stream || newVideoMediaState.ready) {
          this.videoStreamService.remove(newVideoMediaState.stream);
          newVideoMediaState.stream = null;
          newVideoMediaState.ready = false;
        }
        if (newScreenShareMediaState.stream || newScreenShareMediaState.ready) {
          this.videoStreamService.remove(newScreenShareMediaState.stream);
          newScreenShareMediaState.stream = null;
          newScreenShareMediaState.ready = false;
        }
        if (newAudioMediaState.ready) {
          newAudioMediaState.ready = false;
        }
        if (newAudioMediaState.active) {
          newAudioMediaState.active = false;
        }
        this.setState({
          studentOnline: false,
          studentAudioMedia: newAudioMediaState,
          studentScreenShareMedia: newScreenShareMediaState,
          studentVideoMedia: newVideoMediaState,
        });
      }
    } else {
      // Another supervisor has connected or disconnected
      let stateUpdate = {};
      let updateRequired = false;
      let changeUserName = 'Unknown';
      if (type === 'add' && !peers.hasOwnProperty(userId)) {
        updateRequired = true;
        stateUpdate.peers = cloneDeep(peers);
        stateUpdate.peers[userId] = userChangeData;
        changeUserName = userChangeData.userName;
      } else if (type === 'remove' && peers.hasOwnProperty(userId)) {
        updateRequired = true;
        stateUpdate.peers = cloneDeep(peers);
        changeUserName = stateUpdate.peers[userId].userName;
        delete stateUpdate.peers[userId];
      }

      // If the supervisor was sharing video, we need to remove it when they leave
      if (type === 'remove' && videoOverride.peer === userId && videoOverride.ready) {
        this.videoStreamService.remove(videoOverride.stream);
        stateUpdate.videoOverride = {
          peer: null,
          stream: null,
          ready: false,
        };
      }

      if (updateRequired) {
        stateUpdate.notification = {
          show: true,
          message: displaySupervisorPeerName(changeUserName) + (type === 'add' ? ' is also connected' : ' has disconnected'),
        };
        this.setState(stateUpdate);
      }
    }
  }

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their audio state in the call.
   * @param {string} type Type of change, one of 'start', 'stop', 'mute', 'unmute', 'active' or 'inactive'.
   * @param {string} userId The eVig user ID who is sending audio to the meeting.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   */
  onRemoteAudioChange = (type, userId, userChangeData) => {
    const { studentDetails, peers } = this.state;
    console.info(`Audio ${type} notification received for ${userId}`);
    if (userId === studentDetails.id) {
      // Student audio change
      let newAudioMediaState = cloneDeep(this.state.studentAudioMedia);
      switch (type) {
        case 'start':
          newAudioMediaState.ready = true;
          break;
        case 'stop':
          newAudioMediaState.ready = false;
          break;
        case 'mute':
          newAudioMediaState.muted = true;
          break;
        case 'unmute':
          newAudioMediaState.muted = false;
          break;
        case 'active':
          newAudioMediaState.active = true;
          break;
        case 'inactive':
          newAudioMediaState.active = false;
          break;
        default:
          return;
      }
      this.setState({ studentAudioMedia: newAudioMediaState });
    } else {
      // Supervisor audio change
      let stateUpdate = {};
      let updPeers = cloneDeep(peers);
      if (!updPeers.hasOwnProperty(userId)) {
        updPeers[userId] = userChangeData;
      }
      switch (type) {
        case 'mute':
          if (!updPeers[userId].audio.muted) {
            stateUpdate.notification = {
              show: true,
              message: displaySupervisorPeerName(userChangeData.userName) + ' muted themselves',
            }
          }
          updPeers[userId].audio.muted = true;
          break;
        case 'unmute':
          if (updPeers[userId].audio.muted) {
            stateUpdate.notification = {
              show: true,
              message: displaySupervisorPeerName(userChangeData.userName) + ' unmuted themselves',
            };
          }
          updPeers[userId].audio.muted = false;
          break;
        case 'active':
          updPeers[userId].audio.active = true;
          break;
        case 'inactive':
          updPeers[userId].audio.active = false;
          break;
        default:
          return;
      }
      stateUpdate.peers = updPeers;
      this.setState(stateUpdate);
    }
  }

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their screensharing streams, either starting a new stream or stopping an existing one.
   * @param {string} type Type of change, either 'start' or 'stop'.
   * @param {string} userId The eVig user ID who is sharing their screenshare stream.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   */
  onRemoteScreenshareChange = (type, userId, userChangeData) => {
    const { videoConnection } = this.state;
    let newScreenShareMediaState = cloneDeep(this.state.studentScreenShareMedia);
    if (type === 'start') {
      newScreenShareMediaState.stream = userChangeData.screenshare.stream ? userChangeData.screenshare.stream : 'desktop';
      if (videoConnection === MEDIA_STREAM_STATE.CONNECTED) {
        this.videoStreamService.add(newScreenShareMediaState.stream, false, null);
        newScreenShareMediaState.ready = true;
      }
    } else {
      const { stream: oldStream } = this.state.studentScreenShareMedia;
      if (oldStream !== null && videoConnection === MEDIA_STREAM_STATE.CONNECTED) {
        this.videoStreamService.remove(oldStream);
      }
      newScreenShareMediaState.stream = null;
      newScreenShareMediaState.ready = false;
    }
    this.setState({
      studentScreenShareMedia: newScreenShareMediaState,
      screenShareType: SCREEN_SHARE_TYPES.NONE.mapper,
    });
  };

  endEchoTest = () => {
    if (this.state.audioConnection !== MEDIA_STREAM_STATE.CLOSED) {
      this.audioStreamService.transferToConference();
    } else {
      this.setConnectionError('audio', `There was a problem sharing your microphone. Please check your device and allow access before trying again.`);
      this.logToGateway('supervisor_audio_transfer', { error: 'Audio connection not open for transfer from echo test', source: 'endEchoTest' });
    }
  }

  /**
   * Handle an event received from the gateway indicating the meeting has been terminated
   * @param {object} updatedMeetingData The updated meeting information received from the gateway.
   * @param {string} reason Any reason for the meeting shutdown.
   */
  onMeetingEnd = (updatedMeetingData, reason) => {
    const { videoStreamService, audioStreamService } = this;
    const { gatewayConnection } = this.state;
    if (videoStreamService) {
      videoStreamService.unregisterDeviceHandlers('all');
      videoStreamService.close('video');
      videoStreamService.close('screenshare');
    }
    if (audioStreamService) {
      audioStreamService.unregisterHandlers();
      audioStreamService.exitAudio();
    }
    gatewayConnection.unregisterHandler('all');
    gatewayConnection.close();
    this.setState({
      sharedMediaStatus: {
        video: false,
        audio: false,
      },
      studentVideoMedia: {
          ready: false,
          stream: null,
      },
      studentScreenShareMedia: {
        ready: false,
        stream: null,
      },
      studentAudioMedia: {
        ready: false,
        active: false,
      },
      peers: {},
      videoOverride: {
        peer: null,
        stream: null,
        ready: false,
      },
      gatewayConnection: null,
    });
    this.setRequestError('This eVigilation session is now closed.');
  }

  toggleVideoShare = () => {
    let sharedMediaStatus = cloneDeep(this.state.sharedMediaStatus);
    sharedMediaStatus.video = !sharedMediaStatus.video;
    this.setState({ sharedMediaStatus });
  }

  startSharingVideo = () => {
    const { gatewayConnection, connectionProps } = this.state;
    if (gatewayConnection) {
      gatewayConnection.shareMedia('webcam', connectionProps.userId);
    } else {
      console.error('Unable to send update to start sharing webcam. Gateway connection is closed.')
    }
  }

  stopSharingVideo = () => {
    const { gatewayConnection, connectionProps } = this.state;
    if (gatewayConnection) {
      gatewayConnection.unshareMedia('webcam', connectionProps.userId);
    } else {
      console.error('Unable to send update to stop sharing webcam. Gateway connection is closed.');
    }
  }

  toggleAudioMute = () => {
    let sharedMediaStatus = cloneDeep(this.state.sharedMediaStatus);
    const { gatewayConnection } = this.state;
    if (!sharedMediaStatus.audio) {
      sharedMediaStatus.audio = true;
      gatewayConnection.updateMedia('audio', 'muted', false);
    } else {
      sharedMediaStatus.audio = false;
      gatewayConnection.updateMedia('audio', 'muted', true);
    }
    this.setState({ sharedMediaStatus });
  }

  onExamUnlocked = () => {
    const { gatewayConnection } = this.state;
    gatewayConnection.updateMeeting(
      { examState: EXAM_SESSION_STATES.canStart },
      'Supervisor opened exam gate'
    );
  }

  togglePanel = () => {
    const { bigPanel, smallPanel } = this.state;
    this.setState({
      bigPanel: smallPanel,
      smallPanel: bigPanel,
    });
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (!prevProps.authorised && this.props.authorised) {
      this.passBrowserCheck();
    }
  }

  componentWillUnmount() {
    const { gatewayConnection } = this.state;
    this.asyncAbort.abort();
    this.controller.abort();
    if (gatewayConnection) {
      gatewayConnection.leave('Supervisor leaving page');
    }
  }

  getUserError() {
    const { hasRequestErrored, errorMessage, connectionErrors } = this.state;
    if (hasRequestErrored) {
      return errorMessage;
    }
    if (connectionErrors) {
      return Object.values(connectionErrors).join(' ');
    }
    return null;
  }

  closeNotification = () => {
    this.setState({
      notification: {
        show: false,
        message: null,
      }
    });
  }

  /**
   * Log an information message to the gateway for tracking purposes.
   * @param {string} type The code what type of event is being logged
   * @param {object} details An object containing any error message or further details
   */
  logToGateway(type, details) {
    const { gatewayConnection } = this.state;
    if (gatewayConnection) {
      assign(details, {
        userId: this.context.user.id,
        slotId: this.state.examDetails.id,
      });
      gatewayConnection.logInfo(type, details);
    }
  }

  render() {
    const match = this.props.router?.match;
    const {
      examDetails,
      studentDetails,
      connectionProps,
      bigPanel,
      smallPanel,
      studentAudioMedia,
      studentScreenShareMedia,
      studentVideoMedia,
      devices,
      studentOnline,
      videoOverride,
      notification,
    } = this.state;

    const hasAccessToSuperviseOrOnboard = CanThey(this.context.capabilityContextAccess, false, CAPABILITIES.superviseExam) ||
      CanThey(this.context.capabilityContextAccess, false, CAPABILITIES.onboardExam);

    const { videoStreamService } = this;
    const canDisplaySession = !isEmpty(match?.params?.slotId);
    const { audioConnection } = this.state;
    const { streamsChecked, gatewayConnection, sharedMediaStatus } = this.state;
    const errorToDisplay = this.getUserError();

    const SupervisorPage = (
      <>
        {canDisplaySession && examDetails &&
          <>
            <Box display="flex" flexDirection="column" sx={styles.pageContainer}>
              <Box display="flex" color="primary.contrastText" bgcolor="secondary.dark" justifyContent="center">
                <EndButton
                  slotId={match.params.slotId}
                  gatewayOpen={!errorToDisplay && gatewayConnection !== null}
                />
                <ExamDetails examDetails={examDetails} slotId={match.params.slotId} studentDetails={studentDetails} />
              </Box>
              <Box display="flex" flex="1" maxHeight="calc(100vh - 41px)">
                <Box flexGrow={1} display="flex" position="relative">
                  <Fade in={bigPanel === 'webcam' && studentAudioMedia.active} timeout={{enter: 0, exit: 2000}} sx={styles.speakerHighlightActive}>
                    <Box>Student talking</Box>
                  </Fade>
                  <Box flexGrow={1} position="relative" height="calc(100vh - 41px)" display="flex" alignItems="center" sx={styles.panel}>
                    <Box sx={audioConnection === MEDIA_STREAM_STATE.CONNECTED ? styles.audioContainer : styles.audioContainerHidden}>
                      {this.audioStreamService && <AudioStream
                        streamService={this.audioStreamService}
                        connectionStatus={audioConnection}
                        getRef={(ref) => { this.audioRef = ref }}
                        showVolumeControls={true}
                        showMicrophoneMeter={false}
                      />}
                    </Box>
                    {studentOnline === false &&
                      <Box position="absolute" top="0" left="0" right="0" zIndex="1" width="80%" m="0 auto">
                        <Notice noticeType="error" sx={{ root: styles.connectionNotice }}>Student connection lost</Notice>
                      </Box>
                    }
                    {connectionProps &&
                      <Panel
                        waitText
                        panel={bigPanel}
                        videoStreamService={videoStreamService}
                        screenShareType={this.state.screenShareType}
                        streamId={studentVideoMedia.stream}
                        studentWebcamReady={studentVideoMedia.ready}
                        studentDesktopReady={studentScreenShareMedia.ready}
                        onVideoError={(err) => {
                          this.logToGateway(`supervisor_${bigPanel}_view`, { error: err, source: 'bigPanel', stream: studentVideoMedia.stream })
                        }}
                      />
                    }
                    <Box position="absolute" bottom="0" display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%" zIndex={2}>
                      <Box display="flex" flexGrow="1" justifyContent="center">
                        <SupervisorMediaControls
                          sharedMediaStatus={sharedMediaStatus}
                          onAudioToggle={this.toggleAudioMute}
                          onVideoToggle={this.toggleVideoShare}
                          disabled={gatewayConnection === null}
                        />
                      </Box>
                      <Box m={2} mr="50px">
                        <Verification examDetails={examDetails} slotId={match.params.slotId} onExamUnlocked={this.onExamUnlocked} />
                      </Box>
                    </Box>
                  </Box>
                  {sharedMediaStatus.video && <Box sx={styles.selfVideoContainer}>
                    <VideoStream
                      streamService={videoStreamService}
                      streamId={connectionProps.userId}
                      isLocal={true}
                      onVideoStarted={this.startSharingVideo}
                      onVideoStopped={this.stopSharingVideo}
                      onVideoError={(err) => {
                        this.logToGateway('supervisor_webcam_share', { error: err, source: 'selfVideo', stream: connectionProps.userId })
                      }}
                      videoConstraints={{
                        video: { deviceId: devices.videoDevice },
                        audio: false,
                      }}
                    />
                  </Box>}
                  {videoOverride.ready && <Box sx={styles.peerVideoContainer}>
                    <VideoStream
                      streamService={videoStreamService}
                      streamId={videoOverride.stream}
                      isLocal={false}
                      onVideoError={(err) => {
                        this.logToGateway('supervisor_peer_webcam_share', {error: err, source: 'peerVideo', stream: videoOverride.stream})
                      }}
                    />
                  </Box>}
                </Box>
                <Box display="flex" flexDirection="column" maxWidth="40%" minWidth="30%">
                  <Box
                    flexGrow={3}
                    position="relative"
                    height="50%"
                    sx={{ ...styles.panel, ...styles.swapPanelControl }}
                    onClick={this.togglePanel}
                    role="button"
                    aria-label="Swap web cam and screen share"
                  >
                    <Fade in={smallPanel === 'webcam' && studentAudioMedia.active} timeout={{enter: 0, exit: 2000}} sx={styles.speakerHighlightActive}>
                      <Box>Student talking</Box>
                    </Fade>
                    <Tooltip title="Swap webcam and screen share">
                      <SwapHoriz sx={styles.swapIcon} />
                    </Tooltip>
                    {connectionProps &&
                      <Panel
                        panel={smallPanel}
                        videoStreamService={videoStreamService}
                        screenShareType={this.state.screenShareType}
                        streamId={studentVideoMedia.stream}
                        studentWebcamReady={studentVideoMedia.ready}
                        studentDesktopReady={studentScreenShareMedia.ready}
                        onVideoError={(err) => {
                          this.logToGateway(`supervisor_${smallPanel}_view`, { error: err, source: 'smallPanel', stream: studentVideoMedia.stream })
                        }}
                      />
                    }
                  </Box>
                  <Box flexGrow={9} height="50%" display="flex">
                    <ChatContainer
                      gatewayService={gatewayConnection}
                      displayName={has(this.context, 'user.fullName') ? this.context.user.fullName : 'Supervisor'}
                      userId={has(this.context, 'user.id') ? this.context.user.id : ''}
                      authorType={SUPERVISOR.authorType}
                      renderContainer={(gatewayConnection && has(this.context, 'user'))}
                      slotId={match.params.slotId}
                    >
                      <FlagChatToggle
                        slotId={match.params.slotId}
                        slotContextId={examDetails?.context?.id}
                        chatReady={gatewayConnection !== null && (streamsChecked || errorToDisplay)}
                        userToDisplay={studentDetails}
                      />
                    </ChatContainer>

                  </Box>
                </Box>
              </Box>
            </Box>
            <StreamsCheck
              open={(!errorToDisplay && !streamsChecked && !!gatewayConnection)}
              onClose={this.closeCheck}
              onConfirmAudio={this.endEchoTest}
              updateDevices={this.updateDevices}
              devices={this.state.devices}
              startSharingVideo={() => { }}
              audioConnectionState={audioConnection}
              checkScreenshare={false}
              troubleshootLabel="Cancel"
            />
            <NotificationBar
              show={notification.show}
              message={notification.message}
              onClose={this.closeNotification}
            />
          </>
        }
      </>
    );

    const unauthorised = (
      <Box margin={4}>
        <Notice noticeType="error">
          <Typography variant="body1">Sorry, you are not authorised to join this session.</Typography>
        </Notice>
      </Box>
    )

    return (
      <BrowserCheckWrapper onClose={this.passBrowserCheck}>
        <AuthConsumer>
          {({ user }) => (
            <>
              <h1 style={styles.hidden}>eVigilation</h1>
              {!canDisplaySession &&
                <Box ml={4} mr={4}><Notice noticeType="error">Error: exam session is missing</Notice></Box>
              }
              {errorToDisplay &&
                <Box ml={4} mr={4}><Notice noticeType="error">{errorToDisplay}</Notice></Box>
              }
              {examDetails && user &&
                <>
                  {hasAccessToSuperviseOrOnboard ? SupervisorPage : unauthorised}
                </>
              }
            </>
          )}
        </AuthConsumer>
      </BrowserCheckWrapper>
    );
  }
}

SuperviseSession.propTypes = {
  router: PropTypes.object,
};

export default withRouter(SuperviseSession);
SuperviseSession.contextType = authContext;
